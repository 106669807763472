<template>
  <b-card-code>
    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form ref="class_add">
        <b-row>
          <b-col md="4">
            <b-card-text>
              <span>Class Name </span>
            </b-card-text>
            <b-form-group>
              <validation-provider
                  #default="{ errors }"
                  name="class name"
                  rules="required"
              >
                <b-form-input
                    v-model="name"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Class name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- <b-col md="4">
            <b-card-text>
              <span>Select Versions </span>
            </b-card-text>
            <validation-provider
                #default="{ errors }"
                name="version"
                rules="required"
            >
              <v-select
                  v-model="version_ids"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  multiple
                  label="name"
                  :options="versions"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col md="4">
            <b-card-text>
              <span>Select Shifts </span>
            </b-card-text>
            <validation-provider
                #default="{ errors }"
                name="shift"
                rules="required"
            >
              <v-select
                  v-model="shift_ids"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  multiple
                  label="name"
                  :options="shifts"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col> -->
          <b-col md="4">
            <b-card-text>
              <span>Select Student Groups </span>
            </b-card-text>
            <validation-provider
                #default="{ errors }"
                name="student group"
                rules="required"
            >
              <v-select
                  v-model="student_group_ids"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  multiple
                  label="name"
                  :options="student_groups"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col md="4">
            <b-card-text>
              <span>Select Institution </span>
            </b-card-text>
            <validation-provider
                #default="{ errors }"
                name="wing"
                rules="required" >
              <v-select
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  multiple
                  label="name"
                  :options="wings"
                  v-model="wing_ids"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <!-- submit button -->
          <b-col md="12" class="mt-2">
            <b-button
                variant="primary"
                type="submit"
                @click.prevent="saveModifiedInfo"
            >
              Submit
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton,BCardText
} from 'bootstrap-vue'
import {
  required
} from '@validations'
import apiCall from "@/libs/axios";

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    BCardText
  },
  props:{
    id:{
      required:true,
    }
  },
  data() {
    return {
      name: '',
      required,
      versions:[],
      shifts:[],
      student_groups:[],
      wings:[],
      version_ids:[],
      shift_ids:[],
      student_group_ids:[],
      wing_ids:[],
    }
  },
  created(){
    this.getAll();
    this.getClass();
  },
  methods: {
    saveModifiedInfo() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let data =new FormData();
         // let versions=this.version_ids.map(item=>item.id);
          //let shifts=this.shift_ids.map(item=>item.id);
          let student_groups=this.student_group_ids.map(item=>item.id);
          let wings=this.wing_ids.map(item=>item.id);
          data.append('name',this.name);
          data.append('classes_id', this.id);
          // data.append('versions',JSON.stringify(versions));
          data.append('student_groups',JSON.stringify(student_groups));
         // data.append('shifts',JSON.stringify(shifts));
          data.append('wings',JSON.stringify(wings));
          apiCall.post(`/classes/update${this.id}`,data).then((response)=>{
            this.$toaster.success(response.data.message);
            this.$router.go(-1);
          }).catch((error)=>{
            if (error.response.status == 422){
              this.$toaster.error(error.response.data.errors.name);
            }
            else 
            {
              this.$toaster.error(error.response.data.message);
            }
          });
        }
      })
    },
    async getAll(){
      await apiCall.get('/get/active/b/g/r/v/c/s/sg/wings').then((response)=>{
        let data = response.data;
        this.versions=data.versions;
        this.shifts=data.shifts;
        this.student_groups=data.student_groups;
        this.wings=data.wings;
      }).catch(()=>{
        this.versions=[];
        this.shifts=[];
        this.student_groups=[];
        this.wings=[];
      });
    },
    async getClass(){
      await apiCall.get(`/class/info/get/by/id${this.id}`).then((response)=>{
        let data =response.data;
        this.name=data.name;
        this.version_ids=data.versions;
        this.shift_ids=data.shifts;
        this.student_group_ids=data.student_groups;
        this.wing_ids=data.wings;
      }).catch((error)=>{
        this.$toaster.error(error);
        this.$router.go(-1);
      });
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
